<template>
  <div>
    <div v-if="requestCount === 0">
      <createComponent/>
    </div>

    <div v-else-if="requestCount === 1">
      <page1Component/>
    </div>

    <div v-else-if="requestCount === 2">
      <page2Component/>
    </div>

    <div v-else-if="requestCount === 3">
      <page3Component/>
    </div>

    <div v-else-if="requestCount === 4">
      <page4Component/>
    </div>

    <div v-else-if="requestCount === 5">
      <page5Component/>
    </div>

  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import createComponent from '@/components/capital/createComponent';
import page1Component from '@/components/capital/page1Component';
import page2Component from '@/components/capital/page2Component';
import page3Component from '@/components/capital/page3Component';
import page4Component from '@/components/capital/page4Component';
import page5Component from '@/components/capital/page5Component';
export default {
  components: {
    createComponent,
    page1Component,
    page2Component,
    page3Component,
    page4Component,
    page5Component,
  },
  computed: {
    ...mapState({
      requestCount: (state) => state.request.requestCount,
    }),
  },
  methods: {
    ...mapActions("request", ["actionRequestCountAdd"]),
  }
};
</script>

<style scoped>
</style>