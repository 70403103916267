<template>
  <div>
    <header1Component
      :circleActive="0"
      :detailActive="0"
      :loopCircleSmall="3"
      :circleSmall="2"
      titleHeader="หัวข้อ : 1.ข้อมูลขอรับการส่งเสริมและสนับสนุนเงินจากกองทุน"
    />
    <div class="container">
      <div class="card2">
        <div class="title conditionCard2">
          ที่มาของโครงการมีความสอดคล้องกับหัวข้อดังต่อไปนี้
        </div>

        <div class="conditionCard2">
          <div class="content">
            นโยบายและแผนระดับชาติว่าด้วยการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
            (พ.ศ. 2561 - 2580)
          </div>
          <div
            style="margin-top: 10px"
            v-for="(item, index) in items1"
            :key="index"
          >
            <checkboxComponent
              :checkboxId="`checkbox1_${index}`"
              :name="`${item.name}`"
              :value="`${item.name}`"
              @click="checkboxCondition1(`checkbox1_${index}`)"
            />
          </div>
        </div>
        <hr />

        <div class="conditionCard2">
          <div class="content">
            นโยบายและแผนระดับชาติว่าด้วยการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
            (พ.ศ. 2561 - 2580)
          </div>
          <div
            style="margin-top: 10px"
            v-for="(item, index) in items2"
            :key="index"
          >
            <checkboxComponent
              :checkboxId="`checkbox2_${index}`"
              :name="`${item.name}`"
              :value="`${item.name}`"
              @click="checkboxCondition2(`checkbox2_${index}`)"
            />
          </div>
        </div>
        <hr />

        <div class="conditionCard2" style="padding-bottom: 10px">
          <div class="content">
            นโยบาย แผนแม่บท และแผนอื่นๆที่เกี่ยวข้อง (โปรดระบุ)
          </div>
          <div style="margin-top: 10px">
            <div class="flex flex-row items-center">
              <checkboxComponent
                checkboxId="checkboxCondition"
                name=""
                @click="checkboxConditionInput()"
              />
              <input type="text" id="input1" readonly />
              <input type="text" id="input2" v-model="dataSupportHeader3" />
            </div>
          </div>
        </div>

        <hr />

        <div class="conditionCard2">
          <div class="content">
            นโยบายและแผนระดับชาติว่าด้วยการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
            (พ.ศ. 2561 - 2580)
          </div>
          <div
            style="margin-top: 10px"
            v-for="(item, index) in items3"
            :key="index"
          >
            <checkboxComponent
              :checkboxId="`checkbox3_${index}`"
              :name="`${item.name}`"
              :value="`${item.name}`"
              @click="checkboxCondition3(`checkbox3_${index}`)"
            />
          </div>
        </div>
        <hr />

        <div class="flex flex-row">
          <div
            class="w-1/2 flex flex-start justify-start"
            style="margin-left: 72px; margin-top: 81px"
          >
            <button class="buttonPrevious" @click="createRequest()">
              ก่อนหน้า
            </button>
          </div>

          <div
            class="w-1/2 flex items-end justify-end"
            style="margin-right: 72px; margin-top: 81px"
          >
            <button class="buttonNext" @click="updateRequest()">ถัดไป</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useRequest from "../../hooks/useRequest";
import header1Component from "@/components/capital/header1Component";
import checkboxComponent from "@/components/checkbox/checkboxComponent";
export default {
  components: {
    header1Component,
    checkboxComponent,
  },
  mounted() {
    // this.checkboxConditionInput();
    this.getData();
  },
  methods: {
    checkboxCondition1(id) {
      try {
        for (let i = 0; i !== this.items1.length; i++) {
          let checkboxId = document.getElementById(`checkbox1_${i}`);
          if (id === `checkbox1_${i}`) {
            checkboxId.checked = true;
            this.dataSupportHeader1 = checkboxId.value;
          } else {
            checkboxId.checked = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkboxCondition2(id) {
      try {
        for (let i = 0; i !== this.items1.length; i++) {
          let checkboxId = document.getElementById(`checkbox2_${i}`);
          if (id === `checkbox2_${i}`) {
            checkboxId.checked = true;
            this.dataSupportHeader2 = checkboxId.value;
          } else {
            checkboxId.checked = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkboxConditionInput() {
      try {
        let checkboxCondition = document.getElementById("checkboxCondition");
        let input1 = document.getElementById("input1");
        let input2 = document.getElementById("input2");
        if (checkboxCondition.checked === true) {
          input1.style.display = "none";
          input2.style.display = "block";
        } else if (checkboxCondition.checked === false) {
          input1.style.display = "block";
          input2.style.display = "none";
        } else {
          input1.style.display = "block";
          input2.style.display = "none";
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkboxCondition3(id) {
      try {
        for (let i = 0; i !== this.items1.length; i++) {
          let checkboxId = document.getElementById(`checkbox3_${i}`);
          if (id === `checkbox3_${i}`) {
            checkboxId.checked = true;
            this.dataSupportHeader4 = checkboxId.value;
          } else {
            checkboxId.checked = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    getData() {
      useRequest.getProjectId(this.id).then((response) => {
        this.dataSupportHeader1 = response.data.dataSupport1.dataSupportHeader1;
        for (let i = 0; i !== this.items1.length; i++) {
            if (this.items1[i].name === this.dataSupportHeader1) {
                this.checkboxCondition1(`checkbox1_${i}`);
            }
        }

        this.dataSupportHeader2 = response.data.dataSupport1.dataSupportHeader2;
        for (let i = 0; i !== this.items2.length; i++) {
            if (this.items2[i].name === this.dataSupportHeader2) {
                this.checkboxCondition2(`checkbox2_${i}`);
            }
        }

        this.dataSupportHeader3 = response.data.dataSupport1.dataSupportHeader3;
        let checkboxCondition = document.getElementById('checkboxCondition');
        if (this.dataSupportHeader3 !== null && this.dataSupportHeader3 !== '') {
            checkboxCondition.checked = true;
            this.checkboxConditionInput();
        }

        this.dataSupportHeader4 = response.data.dataSupport1.dataSupportHeader4;
        for (let i = 0; i !== this.items3.length; i++) {
            if (this.items3[i].name === this.dataSupportHeader4) {
                this.checkboxCondition3(`checkbox3_${i}`);
            }
        }
      });
    },
    updateRequest() {
      let data = {
        dataSupport1: {
          dataSupportHeader1: this.dataSupportHeader1,
          dataSupportHeader2: this.dataSupportHeader2,
          dataSupportHeader3: this.dataSupportHeader3,
          dataSupportHeader4: this.dataSupportHeader4,
        },
        pageStatus: 3,
      };
      useRequest.updateProject(this.id, data).then((response) => {
        console.log(response.data);
      });
    },
  },
  setup() {
    const dataSupportHeader1 = ref(null);
    const dataSupportHeader2 = ref(null);
    const dataSupportHeader3 = ref(null);
    const dataSupportHeader4 = ref(null);
    const items1 = ref([
      {
        name: "ยุทธศาสตร์ที่ 1 พัฒนาโครงสร้างพื้นฐานดิจิทัลประสิทธิภาพสูงให้ครอบคลุมทั่วประเทศ",
      },
      {
        name: "ยุทธศาสตร์ที่ 2 ขับเคลื่อนเศรษฐกิจด้วยเทคโนโลยีดิจิทัล",
      },
      {
        name: "ยุทธศาสตร์ที่ 3 สร้างสังคมคุณภาพที่ทั่วถึงเท่าเทียมด้วยเทคโนโลยีดิจิทัล",
      },
      {
        name: "ยุทธศาสตร์ที่ 4 ปรับเปลี่ยนภาครัฐสู่การเป็นรัฐบาลดิจิทัล",
      },
      {
        name: "ยุทธศาสตร์ที่ 5 พัฒนนากำลังคนให้พร้อมเข้าสู่ยุคเศรษฐกิจและสังคมดิจิทัล",
      },
      {
        name: "ยุทธศาสตร์ที่ 6 สร้างความเชื่อมั่นในการใช้เทคโนโลยีดิจิทัล",
      },
    ]);
    const items2 = ref([
      {
        name: "ยุทธศาสตร์ที่ 1 พัฒนากำลังคนสู่ยุคดิจิทัล",
      },
      {
        name: "ยุทธศาสตร์ที่ 2 ยกระดับภาคเศรษฐกิจสู่ดิจิทัลไทยแลนด์",
      },
      {
        name: "ยุทธศาสตร์ที่ 3 ขับเคลื่อนชุมชนสู่สังคมดิจิทัล",
      },
      {
        name: "ยุทธศาสตร์ที่ 4 พัฒนาโครงสร้างพื้นฐานรองรับนวัตกรรมดิจิทัล",
      },
    ]);
    const items3 = ref([
      {
        name: "Digital Manpower (การศึกษากับคนรุ่นใหม่ในยุคดิจิทัล)",
      },
      {
        name: "Digital Health (Advanced eHealth)",
      },
      {
        name: "Digital Agriculture (เกษตรเชิงรุกด้วยนวัตกรรมดิจิทัล)",
      },
      {
        name: "Digital Technology (เทคโนโลยีในโลกอนาคต)",
      },
      {
        name: "Digital Government & Infrastructure (รัฐบาลดิจิทัล)",
      },
      {
        name: "Digital Agenda(โครงการตามนโยบายเร่งด่วน)",
      },
    ]);
    return {
      dataSupportHeader1,
      dataSupportHeader2,
      dataSupportHeader3,
      dataSupportHeader4,
      items1,
      items2,
      items3,
    };
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.card2 {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  padding-top: 34px;
  padding-bottom: 36px;
  margin-bottom: 36px;
}

.conditionCard2 {
  margin-left: 73px;
  margin-right: 73px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #242424;
}

hr {
  margin-left: 33px;
  margin-right: 33px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #c4c4c4;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #575757;
}

.contentActive {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
  width: 300px;
  margin-right: 24px;
  margin-left: 48px;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

button {
  width: 195px;
  height: 50px;
  color: white;
  background: #573c81;
  border-radius: 5px;
}

.buttonPrevious {
  width: 195px;
  height: 50px;
  color: #573c81;
  background: #ffffff;
  border: 1px solid #573c81;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>